import { combineReducers } from 'redux';
import msgInfo from './msgInfo';
import userInfo from './userInfo';
import voteOptions from './voteOptions';
import loading from './loading';
import openApp from './openApp';
import browserInfo from './browserInfo';
import { RootState } from '../store.d';

export default combineReducers<RootState>({
  msgInfo,
  userInfo,
  voteOptions,
  loading,
  openApp,
  browserInfo
});
