import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingRoot: {
      height: '100vh'
    }
  })
);

export default function GlobalLoading() {
  const classes = useStyles();
  return (
    <Grid
      classes={{
        root: classes.loadingRoot
      }}
      container
      alignItems="center"
      justify="center"
    >
      <CircularProgress />
    </Grid>
  );
}
