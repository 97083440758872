import { VoteOptions, VoteOptionsAction } from '../store.d';

const initialState: VoteOptions = {
  // type: 1,
  // title: '2132131',
  // desc: '231212132',
  // select: [],
  // start_at: '',
  // end_at: '',
  // multiple_choice: false
};

function voteOptions(state = initialState, action: VoteOptionsAction) {
  const { data } = action;
  switch (action.type) {
    case 'INIT_VOTEOPTIONS': {
      console.log(data);
      const newState = {
        title: '',
        desc: '',
        select: [
          {
            id: 1,
            name: '',
            desc: '',
            img: '',
            in: true,
            ...((data as number) === 4
              ? {
                  address: ''
                }
              : {})
          },
          {
            id: 2,
            name: '',
            desc: '',
            img: '',
            in: true,
            ...((data as number) === 4
              ? {
                  address: ''
                }
              : {})
          }
        ],
        start_at: '',
        end_at: '',
        multiple_choice: false,
        type: data as number,
        ...((data as number) === 4
          ? {
              repeat_choice: false,
              ticket_value: '',
              min_limit: '',
              max_limit: '',
              ticket_owner: '',
              found_address: '',
              show_owner: false
            }
          : {})
      };
      return newState;
    }
    case 'UPDATE_VOTEOPTIONS': {
      const newState = {
        ...state,
        ...(data as VoteOptions)
      };
      return newState;
    }
    default:
      return state;
  }
}

export default voteOptions;
