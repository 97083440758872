import { OpenAction, OpenInfo } from '../store.d';

const initialState: OpenInfo = {
  open: false,
  type: 1,
  appInfo: {}
};

function openApp(state = initialState, action: OpenAction) {
  const { data } = action;
  switch (action.type) {
    case 'SHOW': {
      return {
        open: true,
        ...data
      };
    }
    case 'HIDE': {
      return {
        open: false,
        type: 1,
        appInfo: {}
      };
    }
    default:
      return state;
  }
}

export default openApp;
