import { createMuiTheme } from '@material-ui/core/styles';

const htmlFontSize = 37.5;
const coef = 1; // 缩放倍率
export const pxToRem = (size: number) => `${(size / htmlFontSize) * coef}rem`;

const theme = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        backgroundColor: '#fff',
        marginBottom: pxToRem(20),
        padding: `0 ${pxToRem(20)}`
      }
    },
    MuiSwitch: {
      root: {
        width: 50,
        height: 28,
        padding: 0
      },
      switchBase: {
        padding: 1,
        '&$checked': {
          transform: 'translateX(22px)',
          color: '#fff',
          '& + $track': {
            backgroundColor: 'rgba(55, 111, 255, 1)',
            opacity: 1,
            border: 'none'
          }
        },
        '&$focusVisible $thumb': {
          color: '#52d869',
          border: '6px solid #fff'
        }
      },
      thumb: {
        width: 26,
        height: 26
      },
      track: {
        borderRadius: 28 / 2,
        backgroundColor: 'rgba(120, 120, 128, 0.16)',
        opacity: 1
        // transition: theme.transitions.create(['background-color', 'border']),
      }
    },
    MuiStepper: {
      root: {
        padding: `${pxToRem(15)} 0`,
        marginBottom: pxToRem(20)
      }
    },
    // MuiStep: {
    //   horizontal: {
    //     paddingLeft: 0,
    //     paddingRight: 0
    //   }
    // },
    MuiStepLabel: {
      label: {
        color: '#ccc',
        '&$alternativeLabel': {
          marginTop: 7
        },
        '&$active': {
          color: '#376FFF',
          fontWeight: 400
        },
        '&$completed': {
          color: '#376FFF',
          fontWeight: 400
        }
      }
    },
    MuiStepConnector: {
      alternativeLabel: {
        top: pxToRem(9),
        left: 'calc(-50% + 30px)',
        right: 'calc(50% + 30px)'
      },
      active: {
        '& $line': {
          borderColor: '#376FFF'
        }
      },
      completed: {
        '& $line': {
          borderColor: '#376FFF'
        }
      }
    },
    MuiTypography: {
      gutterBottom: {
        marginBottom: pxToRem(10)
      }
    },
    MuiInputBase: {
      root: {
        fontSize: pxToRem(15),
        lineHeight: 1.4,
        fontWeight: 600
      },
      input: {
        height: 'auto',
        lineHeight: 1.4,
        padding: 0
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#e8e8e8'
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: pxToRem(15),
        color: '#ccc'
      }
    }
  },
  typography: {
    htmlFontSize,
    subtitle1: {
      fontSize: pxToRem(16),
      lineHeight: 1.4
    },
    // 粗体
    subtitle2: {
      fontSize: pxToRem(16),
      lineHeight: 1.4
    },
    body1: {
      fontSize: pxToRem(14),
      lineHeight: 1.4
    },
    body2: {
      fontSize: pxToRem(14),
      lineHeight: 1.4
    },
    button: {
      fontSize: pxToRem(16),
      lineHeight: 1.4
    },
    h1: {
      fontSize: pxToRem(18),
      lineHeight: 1.4,
      fontWeight: 500
    },
    h5: {
      fontSize: pxToRem(15),
      lineHeight: 1.4
    },
    h6: {
      fontSize: pxToRem(13),
      lineHeight: 1.4,
      fontWeight: 400
    },
    // 12px
    caption: {
      lineHeight: 1.4
    }
  },
  palette: {
    text: {
      primary: '#333',
      secondary: '#999'
    },
    primary: {
      main: '#376FFF'
    }
  },
  spacing: (value) => pxToRem(value * 15)
});

console.log(theme);

export default theme;
