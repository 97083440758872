import React from 'react';
import { Route } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import PageHeader from '@/components/PageHeader';
import { RouteConfig } from '@/routes';

const RenderRoute = (route: RouteConfig) => {
  return (
    <Route
      exact={route.exact}
      path={route.path}
      render={(props) => {
        return (
          <Box pt={route.title !== undefined ? 3 : 0} height="100%">
            {route.title !== undefined && (
              <PageHeader
                title={route.title}
                hideHeaderBorder={route.hideHeaderBorder}
                backLink={route.backLink}
              />
            )}
            <route.component {...props} routes={route.routes} />
          </Box>
        );
      }}
    />
  );
};

export default RenderRoute;
