import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalLoading from '@/components/GlobalLoading';
import RenderRoute from '@/components/RenderRoute';
import MessageBox from '@/components/MessageBox';
import FullScreenLoading from '@/components/FullScreenLoading';
import OpenAppConfirmDialog from '@/components/OpenAppConfirmDialog';
import routes from './routes';

function App() {
  return (
    <Suspense fallback={<GlobalLoading />}>
      <BrowserRouter>
        {routes.map((route) => {
          return <RenderRoute key={route.name} {...route} />;
        })}
      </BrowserRouter>
      <MessageBox />
      <FullScreenLoading />
      <OpenAppConfirmDialog />
    </Suspense>
  );
}

export default App;
