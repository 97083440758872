import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

interface PageHeaderProps {
  title?: string;
  hideHeaderBorder?: boolean;
  backLink: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerRoot: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      height: theme.typography.pxToRem(45),
      borderBottom: ({
        hideHeaderBorder
      }: {
        hideHeaderBorder: boolean | undefined;
      }) => (hideHeaderBorder ? '0' : '1px solid #e8e8e8'),
      zIndex: 100,
      backgroundColor: '#fff'
    },
    headerIcon: {
      position: 'absolute',
      zIndex: 10,
      left: theme.typography.pxToRem(8)
    },
    headerText: {
      flex: 1
    }
  })
);

export default function PageHeader(props: PageHeaderProps) {
  const { title, hideHeaderBorder, backLink } = props;
  const history = useHistory();

  const handleBack = () => {
    if (!backLink) {
      history.goBack();
    } else {
      if (backLink === '/view-detail') {
        history.replace(
          history.location.pathname.replace('/view-detail', '/view/election')
        );
      } else {
        history.replace(backLink);
      }
    }
  };

  const classes = useStyles({
    hideHeaderBorder
  });
  return (
    <header className={classes.headerRoot}>
      <IconButton
        aria-label="back"
        className={classes.headerIcon}
        onClick={handleBack}
      >
        <ArrowBackIosRoundedIcon fontSize="small" />
      </IconButton>
      {title && (
        <Typography variant="h1" align="center" className={classes.headerText}>
          {title}
        </Typography>
      )}
    </header>
  );
}
