import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MuiAlert from '@material-ui/lab/Alert';
import { updateMsgInfo } from '@/store/actions';
import Grow from '@material-ui/core/Grow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertWrapper: {
      position: 'fixed',
      top: 20,
      zIndex: 2000
    },
    alertRoot: {
      maxWidth: theme.typography.pxToRem(330),
      margin: '0 auto'
    },
    alertIcon: {
      alignItems: 'center'
    }
  })
);

let timer: any = null;

export default function MessageBox() {
  const classes = useStyles();
  const { open, type, text } = useSelector((state: any) => state.msgInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      timer = setTimeout(() => {
        if (open) {
          dispatch(
            updateMsgInfo({
              open: false
            })
          );
        }
      }, 4000);
    } else {
      clearTimeout(timer);
    }
  }, [dispatch, open]);

  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(
      updateMsgInfo({
        open: false
      })
    );
  };

  return (
    <Grow in={open}>
      <Grid container justify="center" className={classes.alertWrapper}>
        <MuiAlert
          elevation={6}
          onClose={handleClose}
          severity={type}
          classes={{
            root: classes.alertRoot,
            icon: classes.alertIcon
          }}
        >
          {text}
        </MuiAlert>
      </Grid>
    </Grow>
  );
}
