import React from 'react';

function loadComponent(path: string) {
  return React.lazy(() => import('./pages' + path));
}

export interface RouteConfig {
  path: string;
  name: string;
  component: any;
  exact?: boolean;
  title?: string;
  hideHeaderBorder?: boolean;
  routes?: RouteConfig[];
  backLink?: string;
}

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: loadComponent('/Home'),
    exact: true
  },
  {
    path: '/market/0',
    name: 'Market',
    title: '投票市场',
    hideHeaderBorder: true,
    component: loadComponent('/Market'),
    exact: true
  },
  {
    path: '/market/1',
    name: 'InitiateVote',
    title: '我发起的',
    hideHeaderBorder: true,
    component: loadComponent('/Market'),
    exact: true
  },
  {
    path: '/market/2',
    name: 'JoinedVote',
    title: '我参与的',
    hideHeaderBorder: true,
    component: loadComponent('/Market'),
    exact: true
  },
  {
    path: '/vote',
    name: 'Vote',
    component: loadComponent('/Vote'),
    routes: [
      {
        path: '/vote/simple',
        name: 'SimpleVote',
        title: '短文本投票',
        component: loadComponent('/Vote/SimpleVote'),
        exact: true
      },
      {
        path: '/vote/long-text',
        name: 'LongTextVote',
        title: '长文本投票',
        component: loadComponent('/Vote/LongTextVote'),
        exact: true
      },
      {
        path: '/vote/long-text/commit',
        name: 'LongTextCommit',
        title: '长文本投票',
        component: loadComponent('/Vote/LongTextVote/LongTextCommit'),
        exact: true
      },
      {
        path: '/vote/image',
        name: 'ImageVote',
        title: '图片投票',
        component: loadComponent('/Vote/ImageVote'),
        exact: true
      },
      {
        path: '/vote/image/commit',
        name: 'ImageVoteCommit',
        title: '图片投票',
        component: loadComponent('/Vote/ImageVote/ImageVoteCommit'),
        exact: true
      },
      {
        path: '/vote/election',
        name: 'ElectionVote',
        title: '理事会选举投票',
        component: loadComponent('/Vote/ElectionVote'),
        routes: [
          {
            path: '/vote/election/step1',
            name: 'ElectionVoteStep1',
            component: loadComponent('/Vote/ElectionVote/StepOne'),
            exact: true
          },
          {
            path: '/vote/election/step2',
            name: 'ElectionVoteStep2',
            component: loadComponent('/Vote/ElectionVote/StepTwo'),
            exact: true
          },
          {
            path: '/vote/election/step3',
            name: 'ElectionVoteStep3',
            component: loadComponent('/Vote/ElectionVote/StepThree'),
            exact: true
          }
        ]
      }
    ]
  },
  {
    path: '/view/:type/:sid',
    name: 'VoteDetail',
    component: loadComponent('/View')
    // routes: [
    //   {
    //     path: '/view/simple/:id',
    //     name: 'SimpleVoteDetail',
    //     component: loadComponent('/View/Simple'),
    //     exact: true
    //   },
    //   {
    //     path: '/view/long-text/:id',
    //     name: 'LongTextVoteDetail',
    //     component: loadComponent('/View/LongText'),
    //     exact: true
    //   },
    //   {
    //     path: '/view/image/:id',
    //     name: 'ImageVoteDetail',
    //     component: loadComponent('/View/Image'),
    //     exact: true
    //   },
    //   {
    //     path: '/view/election/:id',
    //     name: 'ElectionVoteDetail',
    //     component: loadComponent('/View/Election'),
    //     exact: true
    //   }
    // ]
  },
  {
    path: '/view-detail/:hash',
    name: 'VoteDetailed',
    component: loadComponent('/View/VoteDetailed'),
    title: '投票明细',
    backLink: '/view-detail'
  },
  {
    path: '/result',
    name: 'Result',
    component: loadComponent('/Result'),
    routes: [
      {
        path: '/result/confirming',
        name: 'WaitToConfirm',
        title: '',
        backLink: '/',
        component: loadComponent('/Result/WaitToConfirm')
      }
      // {
      //   path: '/result/confirmation',
      //   name: 'Confirmation',
      //   component: loadComponent('/Result/Confirmation')
      // }
    ]
  }
];

export default routes;
