import { UserInfo, UserInfoAction } from '../store.d';
const spUser: string | null = localStorage.getItem('VOTE_ASSISTANT_UID');
const initialState: UserInfo = spUser ? JSON.parse(spUser) : {};

export default function userInfo(state = initialState, action: UserInfoAction) {
  const { data } = action;
  switch (action.type) {
    case 'ADD_USER': {
      const newState = {
        ...state,
        ...data
      };
      localStorage.setItem('VOTE_ASSISTANT_UID', JSON.stringify(newState));
      return newState;
    }
    case 'DELETE_USER': {
      const newState = {} as UserInfo;
      localStorage.removeItem('VOTE_ASSISTANT_UID');
      return newState;
    }
    default:
      return state;
  }
}
