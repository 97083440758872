import React from 'react';
import { useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { RootState } from '@/store/store.d';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 10000,
      color: '#fff'
    }
  })
);

export default function FullScreenLoading() {
  const loading = useSelector<RootState, boolean>((state) => state.loading);

  const classes = useStyles();
  const handleClose = () => {
    // setOpen(false);
  };

  return (
    <Backdrop className={classes.backdrop} open={loading} onClick={handleClose}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
