import { useLocation } from 'react-router-dom';

/**
 * 给数字保留小数
 * @method formatNumber
 * @param {Number} num 需要保留小数的数字
 * @param {Number} psc 保留的精度
 * @returns {Number} 保留后的数字
 */
export function formatNumber(num: number, psc: number = 5) {
  const formatNum = parseFloat(num.toString());
  const decimals = formatNum.toString().split('.')[1] || '';
  return decimals.length > psc ? formatNum.toFixed(psc) : formatNum;
}

function addZero(n: number) {
  return n < 10 ? '0' + n : n;
}

export function formatTime(dateStr: string | Date) {
  let date = new Date(dateStr);
  const offset = 8 + date.getTimezoneOffset() / 60;
  return (
    [date.getFullYear(), date.getMonth() + 1, date.getDate()]
      .map(addZero)
      .join('-') +
    ' ' +
    [date.getHours() + offset, date.getMinutes(), date.getSeconds()]
      .map(addZero)
      .join(':')
  );
}

export function formatDiff(difficulty: number, digit: number = 2) {
  const diffi = difficulty.toString();

  if (diffi.length < 4) {
    return `${difficulty} H/s`;
  }

  if (diffi.length < 7) {
    return `${parseFloat((difficulty / Math.pow(10, 3)).toFixed(digit))} KH/s`;
  }

  if (diffi.length < 10) {
    return `${parseFloat((difficulty / Math.pow(10, 6)).toFixed(digit))} MH/s`;
  }

  if (diffi.length < 13) {
    return `${parseFloat((difficulty / Math.pow(10, 9)).toFixed(digit))} GH/s`;
  }

  if (diffi.length < 16) {
    return `${parseFloat((difficulty / Math.pow(10, 12)).toFixed(digit))} TH/s`;
  }

  return `${(difficulty / Math.pow(10, 15)).toFixed(digit)} PH/s`;
}

export function formatHash(hashes: number = 0) {
  var result = 0;
  var unit = 'K';

  if (hashes !== 0 && hashes < 1000) {
    result = hashes;
    unit = '';
  }

  if (hashes >= 1000 && hashes < Math.pow(1000, 2)) {
    result = hashes / 1000;
    unit = 'K';
  }

  if (hashes >= Math.pow(1000, 2) && hashes < Math.pow(1000, 3)) {
    result = hashes / Math.pow(1000, 2);
    unit = 'M';
  }

  if (hashes >= Math.pow(1000, 3) && hashes < Math.pow(1000, 4)) {
    result = hashes / Math.pow(1000, 3);
    unit = 'G';
  }

  if (hashes >= Math.pow(1000, 4) && hashes < Math.pow(1000, 5)) {
    result = hashes / Math.pow(1000, 4);
    unit = 'T';
  }

  return formatNumber(result, 2) + unit + 'H/s';
}

export function isEmptyObject(obj: object) {
  let name;
  for (name in obj) {
    return false;
  }
  return true;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function getBrowser() {
  const ua = window.navigator.userAgent || '';
  const isAndroid = /android/i.test(ua);
  const isIos = /iphone|ipad|ipod/i.test(ua);
  const isWechat = /micromessenger\/([\d.]+)/i.test(ua);
  const isQQ = /qq\/([\d.]+)/i.test(ua);
  const isDingDing = /dingtalk\/([\d.]+)/i.test(ua);

  return {
    isAndroid,
    isIos,
    isWechat,
    isQQ,
    isDingDing,
    isBlackBrowser: isWechat || isQQ || isDingDing
  };
}

// 数组打乱顺序
export function shuffle(array: any[]) {
  let m = array.length;
  let t;
  let i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(Math.random() * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}
