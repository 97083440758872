import { MsgInfo, UserInfo, VoteOptions, OpenInfo } from '../store.d';

export const updateMsgInfo = (data: Partial<MsgInfo>) => ({
  type: 'UPDATE_MSG',
  data
});

export const addUser = (data: UserInfo) => ({
  type: 'ADD_USER',
  data
});
export const deleteUser = () => ({
  type: 'DELETE_USER'
});

export const initVoteOptions = (data: number) => ({
  type: 'INIT_VOTEOPTIONS',
  data
});

export const updateVoteOptions = (data: Partial<VoteOptions>) => ({
  type: 'UPDATE_VOTEOPTIONS',
  data
});

export const showLoading = () => ({
  type: 'SHOW_LOADING'
});

export const hideLoading = () => ({
  type: 'HIDE_LOADING'
});

export const showOpenApp = (data: Partial<OpenInfo>) => ({
  type: 'SHOW',
  data
});

export const hideOpenApp = () => ({
  type: 'HIDE'
});
