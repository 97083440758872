import { MsgInfo, MsgInfoAction } from '../store.d';

const initialState: MsgInfo = {
  type: 'error',
  text: '',
  open: false
};

function msgInfo(state = initialState, action: MsgInfoAction) {
  const { data } = action;
  switch (action.type) {
    case 'UPDATE_MSG': {
      const newState = {
        ...state,
        ...data
      };
      return newState;
    }
    default:
      return state;
  }
}

export default msgInfo;
