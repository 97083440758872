import { LoadingAction } from '../store.d';

const initialState: boolean = false;

function loading(state = initialState, action: LoadingAction) {
  // const { data } = action;
  switch (action.type) {
    case 'SHOW_LOADING': {
      return true;
    }
    case 'HIDE_LOADING': {
      return false;
    }
    default:
      return state;
  }
}

export default loading;
