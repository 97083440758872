import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Grid
} from '@material-ui/core';
import { RootState, OpenInfo } from '@/store/store.d';
import { hideOpenApp } from '@/store/actions';

export default function OpenAppConfirmDialog() {
  const { open, type, appInfo } = useSelector<RootState, OpenInfo>(
    (state) => state.openApp
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideOpenApp());
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} maxWidth="xs">
      <DialogTitle>
        <Typography variant="subtitle2" align="center" component="p">
          提示
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText align="justify" color="textPrimary">
          {type === 1
            ? '您尚未登录，请使用SIPC.VIP APP授权登录。若您未安装该APP，请先下载。'
            : '即将打开SIPC.VIP支付，确定打开吗？'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justify="center">
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'center'
            }}
          >
            <Button
              size="small"
              onClick={() => {
                window.location.href = 'http://download.sipc.vip';
              }}
              color="default"
            >
              去下载
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'center'
            }}
          >
            <Button
              size="small"
              onClick={() => {
                window.location.href = `sipcvip://platformapi/${
                  type === 1 ? 'authorizedlogin' : 'pay'
                }?appInfo=${JSON.stringify({
                  ...appInfo,
                  loginURL: encodeURIComponent(appInfo.loginURL),
                  callbackURL: encodeURIComponent(appInfo.callbackURL)
                })}`;
              }}
              color="primary"
            >
              打开APP
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
